import React from "react"
import Layout from "../components/layout"
import ContactUs from '../components/contact'
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout page="404">
    <SEO title="Rethink Main - Oops Page Not Found" />
    <ContactUs />
  </Layout>
)

export default NotFoundPage
